import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { OverviewBudget } from "../../overview/overview-budget";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import Example from "../../pagination/Pagination";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import { FlashAutoTwoTone } from "@mui/icons-material";

type Props = {};

const HOverviewPage = (props: Props) => {
  const [happinessQuotient, setHappinessQuotient] = useState<any>([]);
  const [bloodGroup, setBloodGroup] = useState([]);
  const [envFactor, setEnvFactor] = useState([]);
  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      getJobDetails();
      bloodGroupDistributionChart();
      happinessQuotientChart();
      environmentFactorCharts();
    }
    isMounted.current = true;
  }, []);

  const bloodGroupsData = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const envFactorData = [
    "Environment",
    "Stagnate",
    "Meat Shop",
    "Garbage disposal",
    "Sanitation",
    "Mobile Tower",
  ];

  const getJobDetails = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.PROGRESS_REPORT)
      .then((response) => {
        setJobList(response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bloodGroupDistributionChart = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.bloodGroupDistributionChart)
      .then((response) => {
        let arraySet: any = [];
        for (let i = 0; i < bloodGroupsData.length; i++) {
          let value =
            response.data.find((e: any) => e.BloodGroup === bloodGroupsData[i])
              ?.Count ?? null;

          arraySet.push({ BloodGroup: bloodGroupsData[i], Count: value ?? 0 });
        }
        setBloodGroup(arraySet);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const environmentFactorCharts = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.environmentFactorCharts)
      .then((response) => {
        let arraySet: any = [];
        for (let i = 0; i < envFactorData.length; i++) {
          let value =
            response.data.find((e: any) => e.question === envFactorData[i])
              ?.Count ?? null;

          arraySet.push({ question: envFactorData[i], Count: value ?? 0 });
        }

        console.log(arraySet);

        setEnvFactor(arraySet);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const happinessQuotientChart = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.happinessQuotientChart)
      .then((response) => {
        let arraySet: any = [];
        for (let i = 1; i <= 10; i++) {
          let value =
            response.data.find((e: any) => e.rating === i.toString())?.Count ??
            null;
          arraySet.push({ rating: i, Count: value ?? 0 });
        }
        setHappinessQuotient(arraySet);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update screen dimensions on window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                value={jobList.length}
                text="Customers"
                subtext="Current customers count"
                color="rgb(8 55 108 / 87%)"
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="moringa Score"
                subtext="Low moringa score"
                color="rgb(37 109 133 / 89%)"
                value={jobList.filter((e) => e["Moringa Score"] < 70).length}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="Sickness Score"
                subtext="High sickness score"
                color="rgb(43 72 101 / 83%)"
                value={jobList.filter((e) => e["Sickness Score"] > 50).length}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <OverviewBudget
                difference={12}
                positive
                sx={{ height: "100%" }}
                text="Behaviour Score"
                color="rgb(75 140 124 / 96%)"
                subtext="Low behaviour score"
                value={jobList.filter((e) => e["Behaviour Score"] < 50).length}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid xs={12} sm={12} lg={12}>
              <Card className="mt-4">
                <CardContent>
                  <h5 className="ml-4 mb-3">Blood Group Distribution Chart</h5>
                  <BarChart
                    width={screenWidth / 1.5}
                    height={300}
                    data={bloodGroup}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="BloodGroup"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#518673" />
                  </BarChart>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* <Grid
            container
            spacing={3}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <Grid
              xs={12}
              sm={12}
              lg={12}
              justifyContent={"center"}
              justifyItems={"center"}
            >
              <Card className="mt-4">
                <CardContent>
                  <h5 className="ml-4 mb-3">Happiness Quotient</h5>
                  <BarChart
                    width={screenWidth / 1.5}
                    height={300}
                    data={happinessQuotient}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="rating" padding={{ left: 10, right: 10 }} />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#518673" />
                  </BarChart>
                </CardContent>
              </Card>
            </Grid>
          </Grid> */}
          <Grid
            container
            spacing={3}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <Grid
              xs={12}
              sm={12}
              lg={12}
              justifyContent={"center"}
              justifyItems={"center"}
            >
              {/* <Card className="mt-4">
                <CardContent>
                  <h5 className="ml-4 mb-3">Environment Factor Charts</h5>
                  <BarChart
                    width={screenWidth / 1.5}
                    height={300}
                    data={envFactor}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="question"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#518673" />
                  </BarChart>
                </CardContent>
              </Card> */}
            </Grid>
          </Grid>
        </Container>
        <Example
          jobList={jobList}
          envFactor={envFactor}
          isInsurance={false}
        ></Example>
      </Box>
      <FullScreenLoader isLoading={isLoading} />
    </>
  );
};

{
  /* <Grid xs={12} sm={12} lg={4}>
<Card className="mt-4">
  <CardContent>
    <PieChart width={screenWidth / 4.5} height={300}>
      <Pie
        data={data2}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#518673"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  </CardContent>
</Card>
</Grid> */
}

export default HOverviewPage;
