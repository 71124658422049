import { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
} from "reactstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";

const AddEmployeeModal = ({
  isOpen,
  onClose,
  onConfirm,
  triggerLoader,
}: any) => {
  const [empInfo, setEmpInfo] = useState<any>({
    index: "",
    employee_id: "",
    customer_name: "",
    email: "",
    phone_number: "",
    Manager: "",
    location: "",
    deviceId: "",
    shift: "",
  });

  const fieldShow = [
    { label: "Employee Id", key: "employee_id", type: "input" },
    { label: "Name", key: "customer_name", type: "input" },
    { label: "Email", key: "email", type: "input" },
    { label: "Phone Number", key: "phone_number", type: "input" },
    {
      label: "Manager",
      key: "Manager",
      type: "select",
      option: [
        { label: "Shivani Reddy", value: "Shivani Reddy" },
        { label: "Sunil Shetty", value: "Sunil Shetty" },
        { label: "Ramesh Nair", value: "Ramesh Nair" },
      ],
    },
    {
      label: "Shift",
      key: "shift",
      type: "select",
      option: [
        { label: "Shift 1", value: "1" },
        { label: "Shift 2", value: "2" },
      ],
    },
    {
      label: "location",
      key: "location",
      type: "select",
      option: [
        { label: "Sion Circle, Mumbai", value: "1" },
        { label: "Emfotech Park, Thane", value: "2" },
      ],
    },
  ];

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setEmpInfo((prevInfo: any) => ({
      ...prevInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpload = () => {
    const location = [
      { label: "Emfotech Park, Thane", value: "2" },
      { label: "Sion Circle, Mumbai", value: "1" },
    ];

    empInfo.deviceId = empInfo.location;
    empInfo.location = location.find(
      (e) => e.value === empInfo.location
    )?.label;

    axiosInstance
      .post(urlConfigs.GetEmployee, empInfo)
      .then((response) => {
        onConfirm();
        triggerLoader(false);
      })
      .catch((error) => {
        triggerLoader(false);
      });
  };

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={onClose}>
      <Form onSubmit={handleUpload}>
        <ModalHeader toggle={onClose}>Add Employee</ModalHeader>
        <ModalBody>
          <Row>
            {fieldShow.map((e: any, i) => (
              <Col lg={6} key={i}>
                {e.type === "select" ? (
                  <FormControl fullWidth className="mb-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      {e.label}
                    </InputLabel>
                    <Select
                      name={e.key}
                      id="outlined-adornment-email"
                      label={e.key}
                      onChange={handleChange}
                      onClose={(e) => {
                        document.body.style.overflow = "visible";
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {e.option.map((op: any, i: number) => (
                        <MenuItem key={i} value={op.value}>
                          {op.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-email">
                      {e.label}
                    </InputLabel>
                    <OutlinedInput
                      name={e.key}
                      id="outlined-adornment-email"
                      label={e.key}
                      onChange={handleChange}
                      required
                    />
                  </FormControl>
                )}
              </Col>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleUpload}>
            Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEmployeeModal;
