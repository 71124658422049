import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ErrorAlertModal from "../../components/common/ErrorAlertModal";

interface IFormData {
  message: string;
}

function ContactForm() {
  const [formData, setFormData] = useState<IFormData>({
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("Message:", formData.message);

    setFormData({ message: "" });
    setIsSubmitted(true);
  };

  return (
    <Container maxWidth="xl">
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={12} sm={12} lg={5}>
          <Card className="mt-4">
            <CardContent>
              <h5 className="mb-5">Contact Us</h5>
              <FormControl fullWidth className="mb-3">
                <InputLabel htmlFor="outlined-adornment-name">
                  Company Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-name"
                  label="name"
                  value={""}
                  readOnly
                />
              </FormControl>
              <FormControl fullWidth className="mb-3">
                <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-name"
                  label="name"
                  value={""}
                  readOnly
                />
              </FormControl>
              <FormControl fullWidth className="mb-3">
                <InputLabel htmlFor="outlined-adornment-email">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  label="email"
                  value={""}
                  readOnly
                />
              </FormControl>
              <FormControl fullWidth className="mb-4">
                <InputLabel htmlFor="outlined-adornment-phoneNumber">
                  Message
                </InputLabel>
                <OutlinedInput
                  style={{ height: "6rem" }}
                  id="outlined-adornment-phoneNumber"
                  label="phoneNumber"
                  name="message"
                  onChange={handleChange}
                  value={formData.message}
                  multiline
                />
              </FormControl>
              <div
                className="create-btn text-center"
                onClick={() => {
                  handleSubmit();
                }}
                style={{
                  textDecoration: "none",
                  background: "#212529",
                }}
              >
                Submit
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ErrorAlertModal
        isOpen={isSubmitted}
        onClose={() => setIsSubmitted(false)}
        title={"Thank you for getting in touch!"}
        body={
          "We appreciate you contacting us. One of our colleagues will get back in touch with you soon!"
        }
      ></ErrorAlertModal>
    </Container>
  );
}

export default ContactForm;
