import { RouteType } from "./config";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import {
  Diversity1Outlined,
  GraphicEqOutlined,
  SpeedOutlined,
  LocalHospital,
  ReportGmailerrorred,
  HorizontalSplitSharp,
  AnalyticsOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import OverviewPage from "../pages/overview/OverviewPage";
import CollectionsPage from "../pages/collections/CollectionsPage";
import RecordsPage from "../pages/records/RecordsPage";
import ReportsPage from "../pages/reports/ReportsPage";
import ProfilePage from "../pages/profile/ProfilePage";
import HOverviewPage from "../pages/hospital/h-overview/HOverviewPage";
import HUploadReportView from "../pages/hospital/h-uploadReportView/HUploadReportView";
import HCustomerReportPage from "../pages/hospital/h-customerReportsView/HCustomerReportPage";
import HHospitalDetailsPage from "../pages/hospital/h-hostpitalDetails/HHospitalDetailsPage";
import GovSingleForm from "../pages/government/singleFrom/singleFrom";
import GovCustomerRecordsPage from "../pages/government/customerList/customerList";
import HManagementReportPage from "../pages/hospital/h-managementReport/HManagementReport";
import HCustomerReportView from "../pages/hospital/h-customerReportView/HCustomerReportView";
import HProfilePage from "../pages/hospital/h-profile/HProfilePage";
import PHomeScreen from "../pages/pharmacy/p-home_view/PHomeScreen";
import PUploadRecordScreen from "../pages/pharmacy/p-upload-record/PUploadRecordScreen";
import PCustomerRecordScreen from "../pages/pharmacy/p-customer-record/PCustomerRecordScreen";
import PCustomerReportView from "../pages/pharmacy/p-customer-report/PCustomerReportView";
import VUsermanagementView from "../pages/volunteer/v-userManagement/VUsermanagementView";
import VHomeView from "../pages/volunteer/v-homeview/VHomeView";
import CHomePage from "../pages/callCenter/c-homepage/CHomePage";
import CDashboard from "../pages/callCenter/c-dashboard/CDashboard";
import CCreateTicket from "../pages/callCenter/c-createTicket/CCreateTicket";
import DataInsights from "../pages/dataInsights/DataInsights";
import WeatherPage from "../pages/weatherPage/WeatherPage";
import ContactUs from "../pages/contactUs/contactUs";
import ContactForm from "../pages/contactUs/contactUs";
import EsgDashboard from "../pages/esgDashboard/esgDashboard";
import UserManagement from "../pages/userManagement/UserManagementPage";

const appRoutes: RouteType[] = [
  // {
  //   path: "/insurance/weather",
  //   element: <OverviewPage />,
  //   state: "weather",
  //   role: ["admin"],
  //   company_type: ["Insurance"],
  //   sidebarProps: {
  //     displayText: "Data Insights",
  //     icon: <SpeedOutlined />,
  //   },
  // },
  {
    path: "/insurance/esg",
    element: <EsgDashboard />,
    state: "ESG",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "ESG Dashboard",
      icon: <SpeedOutlined />,
    },
  },
  {
    path: "/insurance/overview",
    element: <OverviewPage />,
    state: "overview",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "Data Insights",
      icon: <AnalyticsOutlined />,
    },
  },

  {
    path: "/insurance/insights",
    element: <DataInsights />,
    state: "insights",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "Business Insights",
      icon: <BusinessIcon />,
    },
  },

  {
    path: "/insurance/collections",
    element: <CollectionsPage />,
    state: "collections",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "Collections",
      icon: <Diversity1Outlined />,
    },
  },

  {
    path: "/insurance/records",
    element: <RecordsPage />,
    state: "records",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "Records",
      icon: <GraphicEqOutlined />,
    },
  },
  {
    path: "/insurance/reports",
    element: <ReportsPage />,
    state: "reports",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "Reports",
      icon: <FileDownloadOutlinedIcon />,
    },
  },
  {
    path: "/insurance/profile",
    role: ["admin"],
    company_type: ["Insurance"],
    element: <ProfilePage />,
    state: "Profile",
  },
  {
    path: "/insurance/contactUs",
    role: ["admin"],
    company_type: ["Insurance"],
    element: <ContactForm />,
    state: "ContactUs",
  },
  {
    path: "/hospital/overview",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HOverviewPage />,
    sidebarProps: {
      displayText: "Overview Page",
      icon: <SpeedOutlined />,
    },
    state: "Overview",
  },
  {
    path: "/hospital/upload",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HUploadReportView />,
    sidebarProps: {
      displayText: "Upload Report",
      icon: <Diversity1Outlined />,
    },
    state: "uploadReport",
  },
  {
    path: "/hospital/details",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HCustomerReportPage />,
    sidebarProps: {
      displayText: "Reports Details",
      icon: <GraphicEqOutlined />,
    },
    state: "HCustomerReportPage",
  },
  {
    path: "/hospital/profile",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HProfilePage />,
    state: "Profile",
  },
  {
    path: "/hospital/detail",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HHospitalDetailsPage />,
    sidebarProps: {
      displayText: "Hospital Details",
      icon: <LocalHospital />,
    },
    state: "HHospitalDetailsPage",
  },
  {
    path: "/hospital/management",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HManagementReportPage />,
    sidebarProps: {
      displayText: "Hospital Management",
      icon: <HorizontalSplitSharp />,
    },
    state: "HManagementReportPage",
  },
  {
    path: "/hospital/customer/report",
    role: ["admin"],
    company_type: ["Hospitals"],
    element: <HCustomerReportView />,
    sidebarProps: {
      displayText: "Customer Report",
      icon: <ReportGmailerrorred />,
    },
    state: "HCustomerReportView",
  },
  //
  {
    path: "/government/form",
    role: ["admin"],
    company_type: ["Government"],
    element: <GovSingleForm />,
    sidebarProps: {
      displayText: "Government Form",
      icon: <SpeedOutlined />,
    },
    state: "GovernmentForm",
  },

  {
    path: "/government/list",
    role: ["admin"],
    company_type: ["Government"],
    element: <GovCustomerRecordsPage />,
    sidebarProps: {
      displayText: "Government List",
      icon: <SpeedOutlined />,
    },
    state: "GovCustomerRecordsPage",
  },

  {
    path: "/pharmacy/overview",
    role: ["admin"],
    company_type: ["Pharmacy"],
    element: <PHomeScreen />,
    sidebarProps: {
      displayText: "Overview",
      icon: <SpeedOutlined />,
    },
    state: "PHomeScreen",
  },

  {
    path: "/pharmacy/upload",
    role: ["admin"],
    company_type: ["Pharmacy"],
    element: <PUploadRecordScreen />,
    sidebarProps: {
      displayText: "Upload Record",
      icon: <SpeedOutlined />,
    },
    state: "PUploadRecordScreen",
  },

  {
    path: "/pharmacy/record",
    role: ["admin"],
    company_type: ["Pharmacy"],
    element: <PCustomerRecordScreen />,
    sidebarProps: {
      displayText: "Customer Record",
      icon: <SpeedOutlined />,
    },
    state: "PCustomerRecordScreen",
  },
  {
    path: "/pharmacy/report",
    role: ["admin"],
    company_type: ["Pharmacy"],
    element: <PCustomerReportView />,
    sidebarProps: {
      displayText: "Reports",
      icon: <SpeedOutlined />,
    },
    state: "PCustomerReportView",
  },

  {
    path: "/volunteer/home",
    role: ["admin"],
    company_type: ["Volunteer"],
    element: <VHomeView />,
    sidebarProps: {
      displayText: "Volunteer Report",
      icon: <SpeedOutlined />,
    },
    state: "VHomeView",
  },

  {
    path: "/volunteer/user",
    role: ["admin"],
    company_type: ["Volunteer"],
    element: <VUsermanagementView />,
    sidebarProps: {
      displayText: "Volunteer User",
      icon: <SpeedOutlined />,
    },
    state: "VUsermanagementView",
  },

  // {
  //   path: "/callcenter/table",
  //   role: ["admin"],
  //   company_type: ["CallCenter"],
  //   element: <CDashboard />,
  //   sidebarProps: {
  //     displayText: "Dashboard",
  //     icon: <SpeedOutlined />,
  //   },
  //   state: "CHomePage",
  // },

  {
    path: "/callcenter/home",
    role: ["admin"],
    company_type: ["CallCenter"],
    element: <CHomePage />,
    sidebarProps: {
      displayText: "Tickets Report",
      icon: <SpeedOutlined />,
    },
    state: "CDashboard",
  },

  {
    path: "/callcenter/create",
    role: ["admin"],
    company_type: ["CallCenter"],
    element: <CCreateTicket />,
    sidebarProps: {
      displayText: "Create Ticket",
      icon: <SpeedOutlined />,
    },
    state: "CCreateTicket",
  },
  {
    path: "/user/manage",
    element: <UserManagement />,
    state: "manage",
    role: ["admin"],
    company_type: ["Insurance"],
    sidebarProps: {
      displayText: "User Management",
      icon: <VerifiedUser />,
    },
  },
];

export default appRoutes;

// {
//   path: "/dashboard",
//   element: <DashboardPageLayout />,
//   state: "dashboard",
//   sidebarProps: {
//     displayText: "Dashboard",
//     icon: <DashboardOutlinedIcon />,
//   },
//   child: [
//     {
//       index: true,
//       element: <DashboardIndex />,
//       state: "dashboard.index",
//     },
//     {
//       path: "/dashboard/default",
//       element: <DefaultPage />,
//       state: "dashboard.default",
//       sidebarProps: {
//         displayText: "Default",
//       },
//     },
//     {
//       path: "/dashboard/analytics",
//       element: <AnalyticsPage />,
//       state: "dashboard.analytics",
//       sidebarProps: {
//         displayText: "Analytic",
//       },
//     },
//     {
//       path: "/dashboard/saas",
//       element: <SaasPage />,
//       state: "dashboard.saas",
//       sidebarProps: {
//         displayText: "Saas",
//       },
//     },
//   ],
// },
// {
//   path: "/component",
//   element: <ComponentPageLayout />,
//   state: "component",
//   sidebarProps: {
//     displayText: "Components",
//     icon: <AppsOutlinedIcon />,
//   },
//   child: [
//     {
//       path: "/component/alert",
//       element: <AlertPage />,
//       state: "component.alert",
//       sidebarProps: {
//         displayText: "Alert",
//       },
//     },
//     {
//       path: "/component/button",
//       element: <ButtonPage />,
//       state: "component.button",
//       sidebarProps: {
//         displayText: "Button",
//       },
//     },
//   ],
// },
// {
//   path: "/documentation",
//   element: <DocumentationPage />,
//   state: "documentation",
//   sidebarProps: {
//     displayText: "Documentation",
//     icon: <ArticleOutlinedIcon />,
//   },
// },
// {
//   path: "/changelog",
//   element: <ChangelogPage />,
//   state: "changelog",
//   sidebarProps: {
//     displayText: "Changelog",
//     icon: <FormatListBulletedOutlinedIcon />,
//   },
// },
