import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ScoreGraphOverview from "./ScoreGraphOverview";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  Unstable_Grid2 as Grid,
  CardContent,
} from "@mui/material";
import { print_city, print_state } from "./cities";
import { Col, Row } from "reactstrap";
import PieChartOverview from "../overview/pie-chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Example = ({ jobList, happinessQuotient, isInsurance }: any) => {
  const [heatMapSeries, setHeatMapSeries] = React.useState(null);
  const [insuranceCover, setInsuranceCover] = React.useState<any>(null);
  const [sicknessVsWellnessChart, setSicknessVsWellnessChart] =
    React.useState();

  const [citiesSelect, setCitiesSelect] = React.useState(null);

  const isMounted = useRef(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update screen dimensions on window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      getCountriesApi();
      healthHeatMap(null);
      SicknessAndWellnesChartApi();
      insuranceCoverChart();
    }

    isMounted.current = true;
  }, []);

  const healthHeatMap = (city: any) => {
    let url = urlConfigs.healthConditionHeatMap;
    if (city != null) {
      url += `?city=${city}`;
    }
    axiosInstance
      .get(url)
      .then((response) => {
        console.log(response.data);

        const ps = response.data.map((e: any) => {
          return {
            name: e["QuestionId"],
            data: [
              { x: "18-20", y: e["18-20"] ?? 0 },
              { x: "21-30", y: e["21-30"] ?? 0 },
              { x: "31-40", y: e["31-40"] ?? 0 },
              { x: "41-50", y: e["41-50"] ?? 0 },
              { x: "51-60", y: e["51-60"] ?? 0 },
              { x: "61-70", y: e["61-70"] ?? 0 },
            ],
          };
        });

        setHeatMapSeries(ps);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const insuranceCoverChart = () => {
    axiosInstance
      .get(urlConfigs.insuranceCover)
      .then((response) => {
        const groupedData = [
          {
            AgeGroup: "18-20",
            InsuranceCover: 200000,
            count: "144",
          },
          {
            AgeGroup: "21-30",
            InsuranceCover: 500000,
            count: "245",
          },
          {
            AgeGroup: "41-50",
            InsuranceCover: 700000,
            count: "358",
          },
          {
            AgeGroup: "51-60",
            InsuranceCover: 400000,
            count: "251",
          },
          {
            AgeGroup: "61-70",
            InsuranceCover: 1000000,
            count: "1",
          },
        ].reduce((result: any, item: any) => {
          const insuranceCover = item.InsuranceCover;
          if (!result[insuranceCover]) {
            result[insuranceCover] = [];
          }
          result[insuranceCover].push(item);
          return result;
        }, {});
        console.log();

        for (const insuranceCover in groupedData) {
          groupedData[insuranceCover].sort((a: any, b: any) => {
            const ageGroupA = a.AgeGroup;
            const ageGroupB = b.AgeGroup;

            // Define the order in which you want to sort the AgeGroup
            const ageGroupOrder = [
              "18-20",
              "21-30",
              "31-40",
              "41-50",
              "51-60",
              "51-60",
              "61-70",
              "71-80",
            ];

            return (
              ageGroupOrder.indexOf(ageGroupA) -
              ageGroupOrder.indexOf(ageGroupB)
            );
          });
        }

        // setInsuranceCover(
        //   Object.keys(groupedData).map((e: any) => {
        //     return {
        //       name: e,
        //       data: [
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "18-20")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "21-30")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "31-40")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "41-50")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "51-60")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "61-70")
        //             ?.count ?? 0
        //         ),
        //         parseInt(
        //           groupedData[e].find((p: any) => p.AgeGroup === "71-80")
        //             ?.count ?? 0
        //         ),
        //       ],
        //     };
        //   })
        // );
        setInsuranceCover([
          {
            name: "20000",
            data: [
              parseInt("4"),
              parseInt("10"),
              parseInt("30"),
              parseInt("50"),
              parseInt("20"),
              parseInt("10"),
              parseInt("60"),
            ],
          },
          {
            name: "40000",
            data: [
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
            ],
          },
          {
            name: "60000",
            data: [
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
            ],
          },
          {
            name: "80000",
            data: [
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
            ],
          },
          {
            name: "100000",
            data: [
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
              parseInt("30"),
            ],
          },
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SicknessAndWellnesChartApi = () => {
    axiosInstance
      .get(urlConfigs.sicknessVsWellnessChart)
      .then((response) => {
        const ps = response.data.map((e: any) => {
          return {
            name: e["QuestionId"],
            data: [
              parseInt(e["18-20"] ?? 0),
              parseInt(e["21-30"] ?? 0),
              parseInt(e["31-40"] ?? 0),
              parseInt(e["41-50"] ?? 0),
              parseInt(e["51-60"] ?? 0),
              parseInt(e["61-70"] ?? 0),
            ],
          };
        });

        console.log(ps);

        setSicknessVsWellnessChart(ps);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 10,
              name: "low",
              color: "#128FD9",
            },
            {
              from: 11,
              to: 15,
              name: "medium",
              color: "#FFB200",
            },
            {
              from: 16,
              to: 100,
              name: "high",
              color: "#FF0000",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
    },
    stroke: {
      width: 1,
    },
    xaxis: {
      title: { text: "Age" },
    },
    title: {
      text: "Age vs Health Condition Heatmap",
    },
  };

  const options3: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: ["18-20", "21-30", "31-40", "41-50", "51-60", "61-70"],
      title: {
        text: "Age",
      },
    },
    yaxis: {
      title: {
        text: "Customers",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Wellness vs Sickness",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val.toString();
        },
      },
    },
  };

  const options2: ApexOptions = {
    chart: {
      type: "bar",
      height: 430,
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },

    title: {
      text: "Age vs Insurance Coverage",
    },
    colors: [
      "rgba(8, 55, 108, 0.87)",
      "rgba(37, 109, 133, 0.89)",
      "rgba(75, 140, 124, 0.96)",
      "rgba(43, 72, 101, 0.83)",
      "rgba(255,196,59,1)",
    ],
    yaxis: {
      title: {
        text: "Age",
      },
    },
    xaxis: {
      categories: [
        "18-20",
        "21-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        "71-80",
      ],
    },
  };

  const optionsHappy: ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#518673", "#545454"],
    xaxis: {
      categories: ["1", "2", "3", "4", "5", "7", "6", "8", "9", "10"],
      title: {
        text: "Rating",
      },
    },
    yaxis: {
      title: {
        text: "Customers",
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "Happiness Quotient",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return val + " Customers";
        },
      },
    },
  };
  var options4: ApexOptions = {
    chart: {
      type: "pie",
    },
    labels: ["0-20%", "20-40%", "40-60%", "60-80%", "80-100%"],
    colors: ["#A8CD9F", "#496989", "#74E291", "#58A399", "#A5DD9B"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const getCitiesApi = (state_id: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/cities/search", {
        state_id: state_id,
      })
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStatesApi = (countryId: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/states/search", {
        country_id: countryId,
      })
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCountriesApi = () => {
    axiosInstance
      .get("https://staging-api.moringa-ai.com/Admin/create/countries")
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="mt-3">
      <Grid container spacing={3} justifyContent={"center"}>
        <Row className="justify-content-center">
          <Col className="mt-4">
            <Card style={{ width: screenWidth / 2.75 }}>
              <CardContent>
                <h5 className="ml-4 mb-3">Wellness Score</h5>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    options={options4}
                    series={[
                      jobList.filter(
                        (e: any) => parseInt(e["Wellness Score"]) <= 20
                      ).length,
                      jobList.filter(
                        (e: any) =>
                          parseInt(e["Wellness Score"]) > 20 &&
                          parseInt(e["Wellness Score"]) < 40
                      ).length,
                      jobList.filter(
                        (e: any) =>
                          parseInt(e["Wellness Score"]) > 40 &&
                          parseInt(e["Wellness Score"]) < 60
                      ).length,
                      jobList.filter(
                        (e: any) =>
                          parseInt(e["Wellness Score"]) > 60 &&
                          parseInt(e["Wellness Score"]) < 80
                      ).length,
                      jobList.filter(
                        (e: any) =>
                          parseInt(e["Wellness Score"]) > 80 &&
                          parseInt(e["Wellness Score"]) < 100
                      ).length,
                    ]}
                    type="pie"
                    width={screenWidth / 2.85}
                    height={300}
                  />
                </div>
              </CardContent>
            </Card>
          </Col>

          <Col>
            <Grid container justifyContent={"center"}>
              <Grid>
                <Card className="mt-4">
                  <CardContent>
                    {/* <h5 className="ml-4 mb-3">Environment Factor Charts</h5>
                    <BarChart
                      width={screenWidth / 2.85}
                      height={300}
                      data={envFactor}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="question"
                        padding={{ left: 10, right: 10 }}
                      />
                      <YAxis allowDecimals={false} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Count" fill="#518673" />
                    </BarChart> */}

                    <Chart
                      options={optionsHappy}
                      series={happinessQuotient}
                      type="bar"
                      width={screenWidth / 2.85}
                      height={325}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Col>
        </Row>
      </Grid>
      {sicknessVsWellnessChart == null ? (
        <></>
      ) : (
        <Grid container spacing={3} justifyContent={"center"}>
          <Row>
            <Col className="mt-4">
              <Card className="p-3 mt-4">
                <Chart
                  options={options3}
                  series={sicknessVsWellnessChart}
                  type="bar"
                  width={screenWidth / 2.85}
                  height={335}
                />
              </Card>
            </Col>
            <Col className="mt-5">
              <Card style={{ width: screenWidth / 2.75 }}>
                <CardContent>
                  <h5 className="ml-4 mb-3">Behaviour Score</h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Chart
                      options={options4}
                      series={[
                        jobList.filter(
                          (e: any) => parseInt(e["Behaviour Score"]) <= 20
                        ).length,
                        jobList.filter(
                          (e: any) =>
                            parseInt(e["Behaviour Score"]) > 20 &&
                            parseInt(e["Behaviour Score"]) < 40
                        ).length,
                        jobList.filter(
                          (e: any) =>
                            parseInt(e["Behaviour Score"]) > 40 &&
                            parseInt(e["Behaviour Score"]) < 60
                        ).length,
                        jobList.filter(
                          (e: any) =>
                            parseInt(e["Behaviour Score"]) > 60 &&
                            parseInt(e["Behaviour Score"]) < 80
                        ).length,
                        jobList.filter(
                          (e: any) =>
                            parseInt(e["Behaviour Score"]) > 80 &&
                            parseInt(e["Behaviour Score"]) < 100
                        ).length,
                      ]}
                      type="pie"
                      width={screenWidth / 2.85}
                      height={300}
                    />
                  </div>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Grid>
      )}

      {isInsurance == false || insuranceCover == null ? (
        <></>
      ) : (
        <Grid container spacing={3} justifyContent={"center"}>
          <Card className="p-3 mt-5">
            <Chart
              options={options2}
              series={insuranceCover}
              type="bar"
              width={screenWidth / 1.38}
              height={350}
            />
          </Card>
        </Grid>
      )}

      {heatMapSeries == null ? (
        <></>
      ) : (
        <>
          <Grid container spacing={3} justifyContent={"center"}>
            <Card className="p-3 mt-5">
              <Col>
                <FormControl sx={{ m: 1, minWidth: "20rem" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={scoreType}
                    label="Select Country"
                    onChange={(e: any) => {
                      getStatesApi(e.target.value);
                    }}
                    onClose={(e) => {
                      document.body.style.overflow = "visible";
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {countries.map((e: any, i: any) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: "20rem" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={scoreType}
                    label="Select State"
                    // onChange={handleChange}
                    onClose={(e) => {
                      document.body.style.overflow = "visible";
                    }}
                    onChange={(e) => {
                      getCitiesApi(e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {states.map((e: any) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: "20rem" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select City
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={scoreType}
                    label="Select City"
                    // onChange={handleChange}
                    onClose={(e) => {
                      document.body.style.overflow = "visible";
                    }}
                    onChange={async (e) => {
                      let cityD: any = cities.find(
                        (p: any) => p.name === e.target.value
                      );

                      healthHeatMap(cityD?.name);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {cities.map((e: any) => {
                      return (
                        <MenuItem key={e.id} value={e.name}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>

              <Chart
                options={options}
                series={heatMapSeries}
                type="heatmap"
                width={screenWidth / 1.38}
                height={350}
              />
            </Card>
          </Grid>
        </>
      )}

      {/* <ScoreGraphOverview /> */}
    </div>
  );
};

export default Example;
