import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import DataInsightsHeaderKeys from "./DataInsightsHeader";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import moment from "moment";

type Props = {};

const DataInsights = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectType, setSelectType] = useState("");

  const [riskAssessment, setRiskAssessment] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [header, setHeader] = React.useState<any>([]);

  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const [startDate, setStartDate] = useState("2024-01-01");
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const [selectedFields, setSelectedFields] = useState<any>({});

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getCountriesApi();
    }

    isMounted.current = true;
  }, []);

  // get citiies state countries
  const getCitiesApi = (state_id: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/cities/search", {
        state_id: state_id,
      })
      .then((response) => {
        setCities(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStatesApi = (countryId: any) => {
    axiosInstance
      .post("https://staging-api.moringa-ai.com/Admin/create/states/search", {
        country_id: countryId,
      })
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCountriesApi = () => {
    axiosInstance
      .get("https://staging-api.moringa-ai.com/Admin/create/countries")
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Handle Start and End Date
  const handleChangeStartDate = (event: any) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event: any) => {
    setEndDate(event.target.value);
  };

  // handle Change

  // Handle Fields Submitted
  const handleFieldsSubmitted = () => {
    console.log("print", selectedFields);
    console.log(startDate);
    console.log(endDate);

    if (selectType == "") {
      return;
    }

    if (selectType === "Risk Assessment Queries") {
      setTableData([]);
      grtRiskAssessment(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.riskAssessmentQueries.map((e: any) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (selectType === "Cost Reduction Queries") {
      setTableData([]);
      getCostReduction(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.costReductionQueries.map((e: any) => {
          if (e.key == "Hospitalized") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => data[e.key] || "No",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (selectType === "Real-time Monitoring Queries") {
      setTableData([]);
      getRealTime(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.realtimeMonitoringQueries.map((e: any) => {
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (selectType === "Tailored Product Development Queries") {
      setTableData([]);
      getTailoredReport(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.tailoredProductDevelopmentQueries.map(
          (e: any) => {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => data[e.key] || "Not Provided",
            };
          }
        )
      );
    } else if (selectType === "Enhanced Customer Engagement Queries") {
      setTableData([]);
      getCustomerEng(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.enhancedCustomerEngagementQueries.map(
          (e: any) => {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => data[e.key] || "Not Provided",
            };
          }
        )
      );
    } else if (selectType === "Fraud Detection and Prevention Queries") {
      setTableData([]);
      getFraudDetection(
        selectedFields?.name,
        `${startDate} 00:00:00`,
        `${endDate} 00:00:00`
      );
      setHeader(
        DataInsightsHeaderKeys.fraudDetectionAndPreventionQueries.map(
          (e: any) => {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) => data[e.key] || "Not Provided",
            };
          }
        )
      );
    } else {
      setTableData([]);

      setHeader("");
    }
  };

  const handleSelectChange = async (event: SelectChangeEvent) => {
    setSelectType(event.target.value);
    if (event.target.value == "") {
      setTableData([]);
      setHeader([]);
    }
  };

  const grtRiskAssessment = (
    city: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/riskAssessment" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.createdDate = moment(element.createdDate).format(
            "DD-MM-YYYY"
          );
        });
        setIsLoading(true);

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCostReduction = (
    city: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/costReduction" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        console.log(response.data);

        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.createdDate = moment(element.createdDate).format(
            "DD-MM-YYYY"
          );
        });

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRealTime = (city: string, startDate: string, endDate: string) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/realTime" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        console.log(response.data);

        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.SCOREDATE = moment(element.SCOREDATE).format("DD-MM-YYYY");
        });

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomerEng = (city: string, startDate: string, endDate: string) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/customerEng" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        console.log(response.data);

        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.createdAt = moment(element.createdAt).format("DD-MM-YYYY");
        });

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFraudDetection = (
    city: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/fraudDetection" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        console.log(response.data);

        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.createdDate = moment(element.createdDate).format(
            "DD-MM-YYYY"
          );
        });

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTailoredReport = (
    city: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);
    axiosInstance
      .get(
        urlConfigs.INSIGHTS +
          "/tailoredReport" +
          "?city=" +
          city +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate
      )
      .then((response) => {
        console.log(response.data);

        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.date = moment(element.date).format("DD-MM-YYYY");
        });

        response.data.sort((a: any, b: any) => {
          return parseInt(b.COUNT) - parseInt(a.COUNT);
        });

        setTableData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container fluid className="vh-100" style={{ minWidth: "100%" }}>
      <Navbar className="mt-2 mb-2" color="light">
        <FormControl sx={{ minWidth: "40%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Queries
          </InputLabel>
          <Select
            name="select_volunteer"
            id="outlined-adornment-email"
            label="Select Queries"
            onChange={handleSelectChange}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {[
              "Risk Assessment Queries",
              "Cost Reduction Queries",
              "Real-time Monitoring Queries",
              "Tailored Product Development Queries",
              "Enhanced Customer Engagement Queries",
              "Fraud Detection and Prevention Queries",
            ].map((e: any) => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Navbar>
      <Col>
        <FormControl sx={{ m: 1, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Country
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            // value={scoreType}
            onChange={(e: any) => {
              getStatesApi(e.target.value);
            }}
            label="Select Country"
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {countries.map((e: any, i: any) => {
              return (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select State
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            // value={scoreType}
            label="Select State"
            // onChange={handleChange}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
            onChange={(e) => {
              getCitiesApi(e.target.value);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {states.map((e: any) => {
              return (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select City
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            // value={scoreType}
            label="Select City"
            // onChange={handleChange}
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
            onChange={async (e) => {
              let cityD: any = cities.find(
                (p: any) => p.name === e.target.value
              );
              setSelectedFields(cityD);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {cities.map((e: any) => {
              return (
                <MenuItem key={e.id} value={e.name}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Col>

      {/* Start and End date */}
      <div className="d-flex justify-content-center">
        <Col>
          <FormControl className="m-3">
            <InputLabel htmlFor="outlined-adornment-customerId">
              Start Date:
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-customerId"
              label="customerId"
              value={startDate}
              type="date"
              onChange={handleChangeStartDate}
            />
          </FormControl>

          <FormControl className="m-3">
            <InputLabel htmlFor="outlined-adornment-end">End Date:</InputLabel>
            <OutlinedInput
              id="outlined-adornment-end"
              label="customerId"
              value={endDate}
              type="date"
              onChange={handleChangeEndDate}
            />
          </FormControl>
        </Col>
        <Button
          className="align-self-center"
          style={{ background: "green" }}
          onClick={handleFieldsSubmitted}
        >
          Submit
        </Button>
      </div>

      <Card
        style={{ borderRadius: 10 }}
        className="shadow p-3 pt-4 mb-5 bg-white "
      >
        <MaterialReactTable
          muiTableHeadCellProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiTableBodyRowProps={{
            sx: {
              backgroundColor: "rgb(228, 241, 221)",
              borderRight: "1px solid rgba(224,224,224,1)",
            },
          }}
          muiTopToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          muiBottomToolbarProps={{
            sx: (theme) => ({
              background: "#faf4d4",
              color: theme.palette.text.primary,
            }),
          }}
          columns={header}
          data={tableData}
          columnResizeMode="onChange"
          initialState={{ showColumnFilters: false, density: "compact" }} //show filters by default
          filterFns={{
            customFilterFn: (row, id, filterValue) => {
              return row.getValue(id) === filterValue;
            },
          }}
        />
      </Card>

      <FullScreenLoader isLoading={isLoading} />
    </Container>
  );
};

export default DataInsights;
