import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import { Card, Container } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { Link } from "react-router-dom";

type Props = {};

const RecordsPage = (props: Props) => {
  const customerBody: any = {
    index: "#",
    id: "Customer id",
    customer_name: "Customer name",
    email: "Email",
    phone_number: "Phone number",
    moringaScore: "moringa Scores",
    createdAt: "Upload data",
    Age: "Age",
    baseLocation: "Location",
    Gender: "Gender",
    Height: "Height",
    Weight: "Weight",
    BMI: "BMI",
    "Blood Group": "Blood Group",
    "Nature of Job": "Nature of Job",
    "Activity Level": "Activity Level",
    "Exercise Level": "Exercise Level",
    "Eating Habits": "Eating Habits",
    "Sleep Duration": "Sleep Duration",
    "Any Surgical History?": "Any Surgical History?",
    "Any Medical History?": "Any Medical History",
    Smoking: "Smoking",
    Drinking: "Drinking",
    "Family Medical History?": "Family Medical History?",
    "Changes in eating habits?": "Changes in eating habits?",
    "Changes in sleeping pattern?": "Changes in sleeping pattern?",
    "Any stress?": "Any stress?",
    "Worry a lot?": "Worry a lot?",
    "Confident?": "Confident?",
    "Need support?": "Need support?",
    "Enjoy community?": "Enjoy community?",
  };

  const tableHeaderData: any = Object.keys(customerBody).map((key) => {
    if (key === "id") {
      return {
        accessor: key,
        accessorKey: key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: customerBody[key],
        Cell: ({ cell, row }: any) => {
          return (
            <Link to={`/insurance/profile?id=${row.original.id}`}>
              {" "}
              {cell.getValue()}
            </Link>
          );
        },
      };
    }
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: customerBody[key],
      accessorFn: (data: any) => data[key] || "Not Provided",
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      setIsLoading(true);
      getJobDetails();
    }

    isMounted.current = true;
  }, []);

  const getJobDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMER_LIST)
      .then((response) => {
        response.data.forEach((element: any, i: any) => {
          element.index = i + 1;
          element.createdAt = new Date(element.createdAt).toLocaleDateString();
          element.customer_name = "*****";
          element.email = "*****";
          element.phone_number = "*****";
          element.age = calculateAge(element?.age);
          element.profile.forEach((e: any) => {
            if (e?.question?.file_field == null) {
              return;
            }
            element[e.question.file_field] = e.answerText;
          });

          delete element.profile;
        });
        setJobList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };

  return (
    <>
      <div>
        <Container fluid className="vh-100">
          <Card
            style={{ borderRadius: 10 }}
            className="shadow p-3 pt-4 mb-5 bg-white "
          >
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={tableHeaderData}
              data={jobList}
              columnResizeMode="onChange"
              initialState={{ showColumnFilters: false, density: "compact" }} //show filters by default
              filterFns={{
                customFilterFn: (row, id, filterValue) => {
                  return row.getValue(id) === filterValue;
                },
              }}
            />
          </Card>
        </Container>
        <FullScreenLoader isLoading={isLoading} />
      </div>
    </>
  );
};

export default RecordsPage;
