import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import urlConfigs from "../../configs/urlCongfig";
import { Card, Col, Container, Nav, Navbar } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { Link } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AddEmployeeModal from "../../components/common/addEployeeModal";

type Props = {};

const UserManagement = (props: Props) => {
  const customerBody: any = {
    employee_id: "Employee id",
    customer_name: "Employee name",
    email: "Email",
    phone_number: "Phone number",
    Manager: "Manager",
    location: "Location",
  };

  const tableHeaderData: any = Object.keys(customerBody).map((key) => {
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: customerBody[key],
      accessorFn: (data: any) => data[key] || "Not Provided",
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState<any>([]);
  const isMounted = useRef(false);

  const names: string[] = ["Shivani Reddy", "Sunil Shetty", "Ramesh Nair"];

  useEffect(() => {
    if (!isMounted.current) {
      setIsLoading(true);
      getEmployee();
      weatherInsideDeviceApi();
      setIsLoading(false);
    }

    isMounted.current = true;
  }, []);

  const [weatherDevice, setWeatherDevice] = useState<any>([]);

  const [csvType, setCsvType] = React.useState("2");

  const [shift, setShift] = React.useState("1");

  const handleChange = async (event: SelectChangeEvent) => {
    setCsvType(event.target.value);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleChangeShift = async (event: SelectChangeEvent) => {
    setShift(event.target.value);
  };
  const weatherInsideDeviceApi = () => {
    axiosInstance
      .get(urlConfigs.WeatherDevice)
      .then((response) => {
        setWeatherDevice(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getEmployee = () => {
    axiosInstance
      .get(urlConfigs.GetEmployee)
      .then((response) => {
        setJobList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Navbar className="mt-2 mb-2" color="light">
        <Nav className="me-auto" navbar></Nav>
        <div
          className="create-btn"
          onClick={() => {
            setOpenModal(true);
          }}
          style={{
            textDecoration: "none",
            background: "#212529",
            marginRight: "1rem",
          }}
        >
          Add Employee
        </div>
      </Navbar>
      <Col>
        <FormControl sx={{ m: 3, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Location
          </InputLabel>
          <Select
            value={csvType}
            onChange={handleChange}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Select Location"
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            {weatherDevice.map((e: any) => (
              <MenuItem value={e.id}>{e.location}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col>
        <FormControl sx={{ ml: 3, mb: 3, minWidth: "20rem" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select Shift
          </InputLabel>
          <Select
            value={shift}
            onChange={handleChangeShift}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Select Shift"
            onClose={(e) => {
              document.body.style.overflow = "visible";
            }}
          >
            <MenuItem value={1}>Shift 1</MenuItem>
            <MenuItem value={2}>Shift 2</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <div>
        <Container fluid className="vh-100">
          <Card
            style={{ borderRadius: 10 }}
            className="shadow p-3 pt-4 mb-5 bg-white "
          >
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={tableHeaderData}
              data={jobList.filter(
                (e: any) => e.deviceId == csvType && e.shift == shift
              )}
              columnResizeMode="onChange"
              initialState={{ showColumnFilters: false, density: "compact" }} //show filters by default
              filterFns={{
                customFilterFn: (row, id, filterValue) => {
                  return row.getValue(id) === filterValue;
                },
              }}
            />
          </Card>
        </Container>
        <AddEmployeeModal
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onConfirm={() => {
            getEmployee();
            setOpenModal(false);
          }}
          isReport={false}
          triggerLoader={(loading: boolean) => {
            setIsLoading(loading);
          }}
        />
        <FullScreenLoader isLoading={isLoading} />
      </div>
    </>
  );
};

export default UserManagement;
