const DataInsightsHeaderKeys = {
  riskAssessmentQueries: [
    // { key: "Country code", label: "Country code" },
    // { key: "Statecode", label: "Statecode" },
    // { key: "Loccode", label: "Loccode" },
    // { key: "Date", label: "Date" },
    { key: "index", label: "#" },
    { key: "customerId", label: "Customer id" },
    // { key: "customerName", label: "Customer Name" },
    { key: "createdDate", label: "Date" },
    { key: "symptomsNames", label: "Symptoms" },
    { key: "diseaseNames", label: "Diseases" },
    { key: "Moringa Score", label: "moringa Score" },
    // { key: "Weather Events", label: "Weather Events" },
    { key: "Sickness Score", label: "Sickness Liklihood Score" },
    { key: "Wellness Score", label: "Wellness Score" },
    { key: "Medical History", label: "Medical History" },
  ],
  costReductionQueries: [
    { key: "index", label: "#" },
    { key: "customerId", label: "Customer Id" },
    { key: "createdDate", label: "Date" },
    // { key: "customerName", label: "Customer Name" },
    // { key: "Not in the system", label: "Not in the system" },
    { key: "symptomsNames", label: "Symptoms" },
    { key: "diseaseNames", label: "Diseases" },
    { key: "Hospitalized", label: "Hospitalized" },
  ],

  realtimeMonitoringQueries: [
    // { key: "Country code", label: "Country code" },
    // { key: "State Code", label: "State Code" },
    // { key: "City Code", label: "City Code" },
    // { key: "Location code", label: "Location code" },
    { key: "index", label: "#" },
    { key: "cId", label: "Customer Id" },
    { key: "SCOREDATE", label: "Date" },

    { key: "Moringa Score", label: "moringas Score" },
    { key: "Sickness Score", label: "Sickness Liklihood Score" },
    { key: "Wellness Score", label: "Wellness Score" },
  ],
  tailoredProductDevelopmentQueries: [
    { key: "index", label: "#" },
    { key: "date", label: "Date" },

    { key: "name", label: "Disease Name" },
    { key: "COUNT", label: "Customer Count" },
    { key: "guidance", label: "Guidance" },
  ],

  enhancedCustomerEngagementQueries: [
    { key: "index", label: "#" },
    { key: "Customer_id", label: "Customer Id" },
    { key: "createdAt", label: "Date" },

    { key: "goalCategory", label: "Goals" },
    { key: "goalType", label: "Goal Type" },
    { key: "CampaignDesc", label: "Campaign" },
  ],
  fraudDetectionAndPreventionQueries: [
    { key: "index", label: "#" },
    { key: "customerId", label: "Customer Id" },
    { key: "createdDate", label: "Date" },

    // { key: "customerName", label: "Customer Name" },
    { key: "symptomsNames", label: "Symptoms" },
    { key: "diseaseNames", label: "Diseases" },
    { key: "Hospitalized", label: "Hospitalized" },
  ],
};

export default DataInsightsHeaderKeys;
