import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import { Card, Container } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import { Link } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import HReportsHeaderKeys from "./PReportHeaderkey";

type Props = {};

const PCustomerReportView = (props: Props) => {
  const [csvType, setCsvType] = React.useState("");
  const [header, setHeader] = React.useState<any>("");
  const customerBody: any = {
    index: "#",
    org_id: "Customer id",
    customer_name: "Customer name",
    email: "Email",
    phone_number: "Phone number",
    moringaScore: "moringa Scores",
    createdAt: "Upload data",
    Age: "Age",
    baseLocation: "Location",
    Gender: "Gender",
    Height: "Height",
    Weight: "Weight",
    BMI: "BMI",
    "Blood Group": "Blood Group",
    "Nature of Job": "Nature of Job",
    "Activity Level": "Activity Level",
    "Exercise Level": "Exercise Level",
    "Eating Habits": "Eating Habits",
    "Sleep Duration": "Sleep Duration",
    "Any Surgical History?": "Any Surgical History?",
    "Any Medical History?": "Any Medical History",
    Smoking: "Smoking",
    Drinking: "Drinking",
    "Family Medical History?": "Family Medical History?",
    "Changes in eating habits?": "Changes in eating habits?",
    "Changes in sleeping pattern?": "Changes in sleeping pattern?",
    "Any stress?": "Any stress?",
    "Worry a lot?": "Worry a lot?",
    "Confident?": "Confident?",
    "Need support?": "Need support?",
    "Enjoy community?": "Enjoy community?",
  };

  const tableHeaderData: any = Object.keys(customerBody).map((key) => {
    return {
      accessor: key,
      accessorKey: key,
      enableColumnFilterModes: false,
      filterFn: "equals",
      header: customerBody[key],
      accessorFn: (data: any) => data[key] || "Not Provided",
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState<any>([]);
  const isMounted = useRef(false);

  const handleChange = async (event: SelectChangeEvent) => {
    setCsvType(event.target.value);

    if (event.target.value === "Customer Report") {
      getJobDetails();

      setJobList([
        {
          id: 1,
          MedicationName: "Albuterol (Ventolin, Proventil)",
          Dosage: "90 mcg to 180 mcg per actuation",
          PharmaCompany:
            "Various generic manufacturers, GlaxoSmithKline, Teva, and others",
          DosageForm:
            "Inhaler (Metered-Dose Inhaler or MDI), Nebulizer Solution",
          Uses: "Albuterol is a bronchodilator used to treat or prevent bronchospasm in people with reversible obstructive airway disease.",
          Duration: "As directed by the healthcare provider.",
        },
        {
          id: 2,
          MedicationName: "Fluticasone/Salmeterol (Advair Diskus, Advair HFA)",
          Dosage:
            "Various strengths of fluticasone (100 mcg, 250 mcg, or 500 mcg) combined with salmeterol (50 mcg)",
          PharmaCompany: "GlaxoSmithKline",
          DosageForm:
            "Inhaler (Dry Powder Inhaler or DPI for Advair Diskus, Metered-Dose Inhaler or MDI for Advair HFA)",
          Uses: "Fluticasone/Salmeterol is used to control and prevent symptoms (such as wheezing and shortness of breath) caused by asthma or ongoing lung disease (chronic obstructive pulmonary disease-COPD, which includes chronic bronchitis and emphysema). It contains two medications: fluticasone and salmeterol. Fluticasone belongs to a class of drugs known as corticosteroids. It works by reducing the irritation and swelling of the airways. Salmeterol belongs to the class of drugs known as long-acting beta agonists. It works by opening airways in the lungs to make breathing easier.",
          Duration: "As directed by the healthcare provider.",
        },
        {
          id: 3,
          MedicationName: "Budesonide/Formoterol (Symbicort)",
          Dosage:
            "Various strengths of budesonide (80 mcg to 320 mcg) combined with formoterol (4.5 mcg or 9 mcg)",
          PharmaCompany: "AstraZeneca",
          DosageForm: "Inhaler (Dry Powder Inhaler or DPI for Symbicort)",
          Uses: "Budesonide/Formoterol is used to control and prevent symptoms (such as wheezing and shortness of breath) caused by asthma or ongoing lung disease (chronic obstructive pulmonary disease-COPD, which includes chronic bronchitis and emphysema). It contains two medications: budesonide and formoterol. Budesonide belongs to a class of drugs known as corticosteroids. It works by reducing the irritation and swelling of the airways. Formoterol belongs to the class of drugs known as long-acting beta agonists. It works by opening airways in the lungs to make breathing easier.",
          Duration: "As directed by the healthcare provider.",
        },
        {
          id: 4,
          MedicationName: "Montelukast (Singulair)",
          Dosage: "4 mg to 10 mg",
          PharmaCompany: "Merck & Co.",
          DosageForm: "Tablet",
          Uses: "Montelukast is used regularly to prevent the wheezing and shortness of breath caused by asthma and decrease the number of asthma attacks. Montelukast is also used before exercise to prevent breathing problems during exercise (bronchospasm). This medication can help decrease the number of times you need to use your rescue inhaler. This medication is also used to relieve symptoms of hay fever and allergic rhinitis (such as sneezing, stuffy/runny/itchy nose).",
          Duration: "As directed by the healthcare provider.",
        },
        {
          id: 5,
          MedicationName: "Levalbuterol (Xopenex)",
          Dosage: "45 mcg to 180 mcg per actuation",
          PharmaCompany: "Sunovion Pharmaceuticals",
          DosageForm:
            "Inhaler (Metered-Dose Inhaler or MDI), Nebulizer Solution",
          Uses: "Levalbuterol is used to prevent and treat wheezing and shortness of breath caused by breathing problems (such as asthma, chronic obstructive pulmonary disease). It is also used to prevent asthma brought on by exercise. It is a quick-relief medication.",
          Duration: "As directed by the healthcare provider.",
        },
        {
          id: 6,
          MedicationName: "Ciclesonide (Alvesco)",
          Dosage: "80 mcg to 320 mcg per actuation",
          PharmaCompany: "Covis Pharma",
          DosageForm: "Inhaler (Metered-Dose Inhaler or MDI)",
          Uses: "Ciclesonide is used to control and prevent symptoms (such as wheezing and shortness of breath) caused by asthma. It works by reducing swelling (inflammation) of the airways in the lungs to make breathing easier.",
          Duration: "As directed by the healthcare provider.",
        },
      ]);
      setHeader(
        HReportsHeaderKeys.medicationData.map((e, i) => {
          if (e.key === "createdDate") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                new Date(data[e.key]).toLocaleDateString() || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key] || "Not Provided",
          };
        })
      );
    } else if (event.target.value === "drug") {
      getJobDetails();

      setJobList([
        {
          country_id: "IN",
          state_id: "MH",
          city_id: "Mumbai",
          location_id: "12345",
          gender: "Male",
          age_band: "30-40",
          pre_existing_conditions: ["Hypertension", "Diabetes"],
          date_month: "2024-02",
          season: "Winter",
          symptoms: ["Fever", "Cough", "Fatigue"],
          disease_id: "10",
          drugs_prescribed: ["Paracetamol", "Azithromycin"],
          drugs_used: ["Paracetamol"],
        },
        {
          country_id: "IN",
          state_id: "DL",
          city_id: "Delhi",
          location_id: "54321",
          gender: "Female",
          age_band: "50-60",
          pre_existing_conditions: ["Asthma", "Heart Disease"],
          date_month: "2024-01",
          season: "Winter",
          symptoms: ["Shortness of breath", "Chest pain"],
          disease_id: "133",
          drugs_prescribed: ["Inhaler", "Beta-blockers"],
          drugs_used: ["Inhaler"],
        },
        {
          country_id: "IN",
          state_id: "KA",
          city_id: "Bangalore",
          location_id: "98765",
          gender: "Male",
          age_band: "20-30",
          pre_existing_conditions: ["None"],
          date_month: "2024-02",
          season: "Winter",
          symptoms: ["Sore throat", "Runny nose"],
          disease_id: "2",
          drugs_prescribed: ["Antihistamines", "Decongestants"],
          drugs_used: ["Antihistamines"],
        },
      ]);
      setHeader(
        HReportsHeaderKeys.medicationDataInfo.map((e, i) => {
          if (e.key === "createdDate") {
            return {
              accessor: e.key,
              accessorKey: e.key,
              enableColumnFilterModes: false,
              filterFn: "equals",
              header: e.label,
              accessorFn: (data: any) =>
                new Date(data[e.key]).toLocaleDateString() || "Not Provided",
            };
          }
          return {
            accessor: e.key,
            accessorKey: e.key,
            enableColumnFilterModes: false,
            filterFn: "equals",
            header: e.label,
            accessorFn: (data: any) => data[e.key].toString() || "Not Provided",
          };
        })
      );
    } else {
      setHeader("");
    }
  };

  const getJobDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMERSYMPTOMTRENDREPORT)
      .then((response) => {
        // setJobList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };

  return (
    <>
      <div>
        <Container fluid className="vh-100">
          <FormControl sx={{ m: 1, minWidth: "20rem" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select Type of report
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={csvType}
              label="Select Type of report"
              onChange={handleChange}
              onClose={(e) => {
                document.body.style.overflow = "visible";
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <MenuItem value={"Customer Report"}>Medication Report</MenuItem>
              <MenuItem value={"drug"}>Medication Info</MenuItem>
            </Select>
          </FormControl>
          {header !== "" && (
            <Card
              style={{ borderRadius: 10 }}
              className="shadow p-3 pt-4 mb-5 bg-white "
            >
              <MaterialReactTable
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                muiTableBodyRowProps={{
                  sx: {
                    backgroundColor: "rgb(228, 241, 221)",
                    borderRight: "1px solid rgba(224,224,224,1)",
                  },
                }}
                muiTopToolbarProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                muiBottomToolbarProps={{
                  sx: (theme) => ({
                    background: "#faf4d4",
                    color: theme.palette.text.primary,
                  }),
                }}
                columns={header}
                data={jobList}
                columnResizeMode="onChange"
                initialState={{ showColumnFilters: false }} //show filters by default
                filterFns={{
                  customFilterFn: (row, id, filterValue) => {
                    return row.getValue(id) === filterValue;
                  },
                }}
              />
            </Card>
          )}
        </Container>
        <FullScreenLoader isLoading={isLoading} />
      </div>
    </>
  );
};

export default PCustomerReportView;
