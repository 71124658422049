import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import urlConfigs from "../../../configs/urlCongfig";
import FileUploadModal from "../../../components/common/FileUploadModal";
import FullScreenLoader from "../../../components/Loaders/FullScreenLoader";
import ErrorAlertModal from "../../../components/common/ErrorAlertModal";

import MaterialReactTable from "material-react-table";
import ProfileReportHeaderKeys from "./HProfileReportHeader";

type Props = {};

const HProfilePage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [csvType, setCsvType] = React.useState("");
  const [header, setHeader] = React.useState<any>(
    ProfileReportHeaderKeys.insuranceTailoringReport.map((e, i) => {
      if (e.key === "createdDate") {
        return {
          accessor: e.key,
          accessorKey: e.key,
          enableColumnFilterModes: false,
          filterFn: "equals",
          header: e.label,
          accessorFn: (data: any) =>
            new Date(data[e.key]).toLocaleDateString() || "Not Provided",
        };
      }
      return {
        accessor: e.key,
        accessorKey: e.key,
        enableColumnFilterModes: false,
        filterFn: "equals",
        header: e.label,
        accessorFn: (data: any) => data[e.key] || "Not Provided",
      };
    })
  );
  const [csvData, setCsvData] = React.useState([]);

  const [reportDetail, setReportDetail] = useState<any>({
    HemoglobinData: [],
    WBCData: [],
    SugarData: [],
  });

  const [isError, setError] = useState({
    error: "",
    active: false,
  });

  const [progress, setProgress] = useState<any>({
    "Moringa Score": "",
    "Behaviour Score": "",
    "Wellness Score": "",
    "Sickness Score": "",
    "Sleep Score": "",
    "Carbon Score": "",
    "Sustainability Score": "",
    Progress: "",
  });

  const moringaCsvHeader = [
    { key: "Age", label: "Age" },
    { key: "Gender", label: "Gender" },
    { key: "BMI", label: "BMI Range" },
    { key: "Nature of Job", label: "Nature of Job" },
    { key: "Activity Level", label: "Activity Level" },
    { key: "Exercise Level", label: "Exercise Level" },
    { key: "Eating Habits", label: "Eating Habits" },
    { key: "Sleep Duration", label: "Sleep Duration" },
    { key: "Any Surgical History?", label: "Any Surgical History?" },
    { key: "Any Medical History?", label: "Any Medical History" },
    { key: "Smoking", label: "Tobacco" },
    { key: "Drinking", label: "Drinking" },
    { key: "Family Medical History?", label: "Family Medical History?" },
    { key: "Any stress?", label: "Mental Health Issues?" },
    { key: "Environment", label: "Environment" },
    { key: "Vaccination", label: "Vaccination" },
  ];

  const ProgressReportHeader = [
    { key: "Moringa Score", label: "moringa Score" },
    { key: "Behaviour Score", label: "Behaviour Score" },
    { key: "Wellness Score", label: "Wellness Score" },
    { key: "Sickness Score", label: "Sickness Score" },
    { key: "Sleep Score", label: "Sleep Score" },
    { key: "Carbon Score", label: "Carbon Score" },
    { key: "Sustainability Score", label: "Sustainability Score" },
    { key: "Progress", label: "Progress" },
  ];

  const [SymList, setSymList] = useState<any>([]);

  const [job, setJob] = useState<any>({
    name: "",
    email: "",
    phoneNumber: "",
    org_id: "",
    Age: "",
    Gender: "",
    BMI: "",
    "Nature of Job": "",
    "Activity Level": "",
    "Exercise Level": "",
    "Eating Habits": "",
    "Sleep Duration": "",
    "Any Surgical History?": "",
    "Any Medical History?": "",
    Smoking: "",
    Drinking: "",
    "Family Medical History?": "",
    "Any stress?": "",
    Environment: "",
    Vaccination: "",
  });
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      setIsLoading(true);
      getJobDetails();
      getReport();
      getSympDetails();
    }

    isMounted.current = true;
  }, []);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get("id");

  const getJobDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMER_PROFILE + "/" + id)
      .then((response) => {
        response.data.age = calculateAge(response?.data?.age);

        response.data.profile.forEach((e: any) => {
          if (e?.question?.file_field == null) {
            return;
          }
          response.data[e.question.file_field] = e.answerText;
        });

        delete response.data.profile;
        setJob(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const getSympDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMERSYMPTOMTRENDREPORT + "/" + id)
      .then((response) => {
        setSymList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const calculateAge = (birthYear: number): number => {
    const currentYear: number = new Date().getFullYear();
    const age: number = currentYear - birthYear;
    return age;
  };

  const getProgressDetails = () => {
    axiosInstance
      .get(urlConfigs.CUSTOMER_PROGRESS + "/" + id)
      .then((response) => {
        if (response.data != null) {
          setCsvData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const getHealthAssessment = () => {
    axiosInstance
      .get(urlConfigs.HEALTHASSESSMENT + "/" + id)
      .then((response) => {
        if (response.data != null) {
          let mapData: any = [{}];

          response.data.forEach((e: any) => {
            mapData[0][e.file_field] = e.answerText;
          });
          setCsvData(mapData);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const getSymptomTrendReport = () => {
    axiosInstance
      .get(urlConfigs.SYMPTOMTRENDREPORT + "/" + id)
      .then((response) => {
        if (response.data != null) {
          setCsvData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  const getReport = () => {
    setIsLoading(true);
    axiosInstance
      .get(urlConfigs.REPORT_UPLOAD + "/" + id)
      .then((response) => {
        const groupedData: any = {};
        response.data.forEach((item: any) => {
          const val: any = item.Parameter;
          if (!groupedData[val]) {
            groupedData[val] = [];
          }
          groupedData[val].push(item);
        });

        const sugarData: any = {};

        groupedData["Sugar"]?.forEach((item: any) => {
          const val: any = item.ReportId;
          if (!sugarData[val]) {
            sugarData[val] = [];
          }
          sugarData[val].push(item);
        });

        const mapData = Object.entries(sugarData).map(([key, value]: any) => {
          return {
            name: value[0].createdAt.split("T")[0],
            Fast:
              value.find((f: any) => f.TestParameter === "Fasting Blood Sugar")
                ?.ValueParameter ?? 0,
            Post:
              value.find(
                (f: any) => f.TestParameter === "Post Prandial Blood Sugar"
              )?.ValueParameter ?? 0,
          };
        });

        console.log(groupedData);

        setReportDetail({
          HemoglobinData:
            groupedData["HemoglobinValue"]?.map((e: any) => {
              return {
                name: e.createdAt.split("T")[0],
                Hemoglobin: e.ValueParameter,
              };
            }) ?? [],
          WBCData:
            groupedData["TotalWBCValue`"]?.map((e: any) => {
              return {
                name: e.createdAt.split("T")[0],
                WBC: e.ValueParameter,
              };
            }) ?? [],
          SugarData: mapData ?? [],
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setError({
          error: "Something went wrong!!!",
          active: true,
        });
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} lg={4}>
              <Card className="mt-4">
                <CardContent>
                  <h5 className="mb-3">Customer Profile</h5>
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-customerId">
                      Customer Id
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-customerId"
                      label="customerId"
                      value={job.org_id}
                      disabled
                    />
                  </FormControl>
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-name">
                      Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-name"
                      label="name"
                      value={job.name}
                      readOnly
                    />
                  </FormControl>
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-email">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      label="email"
                      value={job.email}
                      readOnly
                    />
                  </FormControl>
                  <FormControl fullWidth className="mb-3">
                    <InputLabel htmlFor="outlined-adornment-phoneNumber">
                      Phone Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-phoneNumber"
                      label="phoneNumber"
                      value={job.phoneNumber}
                      readOnly
                    />
                  </FormControl>
                  <div
                    className="create-btn text-center"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    style={{
                      textDecoration: "none",
                      background: "#212529",
                    }}
                  >
                    Upload Report
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} sm={12} lg={8}>
              <Card className="mt-4">
                <CardContent>
                  <h5 className="mb-3">Customer Details</h5>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {moringaCsvHeader.map((e, i) => (
                      <FormControl
                        key={e.key + "from"}
                        sx={{ m: 1, width: "47%" }}
                        variant="outlined"
                      >
                        <InputLabel key={e.key + "input"} htmlFor={e.label}>
                          {e.label}
                        </InputLabel>
                        <OutlinedInput
                          key={e.key + "output"}
                          id={e.label}
                          label={e.label}
                          readOnly
                          value={job[e.key]}
                        />
                      </FormControl>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Card className="mt-4">
              <CardContent>
                <h5 className="mb-3">Customer Score</h5>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {ProgressReportHeader.map((e, i) => (
                    <FormControl
                      key={i + "from"}
                      sx={{ m: 1, width: "47%" }}
                      variant="outlined"
                    >
                      <InputLabel key={i + "input"} htmlFor={e.label}>
                        {e.label}
                      </InputLabel>
                      <OutlinedInput
                        key={i + "output"}
                        id={e.label}
                        label={e.label}
                        readOnly
                        value={progress[e.key]}
                      />
                    </FormControl>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid> */}

          {/* <div className="mt-3">
            <FormControl sx={{ m: 1, minWidth: "20rem" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Select Type of report
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Select Type of report"
                value={csvType}
                onChange={handleChange}
                onClose={(e) => {
                  document.body.style.overflow = "visible";
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"Health Assessment Report"}>
                  Health Assessment Report
                </MenuItem>
                <MenuItem value={"Symptom Trend Report"}>
                  Symptom Trend Report
                </MenuItem>
                <MenuItem value={"Insurance Tailoring report"}>
                  Insurance Tailoring report
                </MenuItem>
              </Select>
            </FormControl>
          </div> */}

          <Card className="mt-5">
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiTableBodyRowProps={{
                sx: {
                  backgroundColor: "rgb(228, 241, 221)",
                  borderRight: "1px solid rgba(224,224,224,1)",
                },
              }}
              muiTopToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              muiBottomToolbarProps={{
                sx: (theme) => ({
                  background: "#faf4d4",
                  color: theme.palette.text.primary,
                }),
              }}
              columns={header}
              data={SymList}
              positionToolbarAlertBanner="bottom"
              enableRowSelection
              state={{
                isLoading,
                showProgressBars: isLoading,
                columnOrder: [
                  "mrt-row-select",
                  ...header.map((e: any) => e.accessorKey),
                ],
              }}
              // renderTopToolbarCustomActions={({ table }) => (
              //   <Box
              //     sx={{
              //       display: "flex",
              //       gap: "1rem",
              //       p: "0.5rem",
              //       flexWrap: "wrap",
              //     }}
              //   >
              //     <Button
              //       color="primary"
              //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              //       onClick={() => {
              //         const data = table.getCoreRowModel().rows.map((value) => {
              //           const obj: any = {};

              //           header.forEach((e: any) => {
              //             obj[e.header] = value.getValue(e.accessor);
              //           });

              //           return obj;
              //         });

              //         exportCSVData(data);
              //       }}
              //       variant="contained"
              //     >
              //       Export All
              //     </Button>

              //     <Button
              //       disabled={table.getRowModel().rows.length === 0}
              //       onClick={() => {
              //         const data = table.getRowModel().rows.map((value) => {
              //           const obj: any = {};

              //           header.forEach((e: any) => {
              //             obj[e.header] = value.getValue(e.accessor);
              //           });

              //           return obj;
              //         });

              //         exportCSVData(data);
              //       }}
              //       variant="contained"
              //     >
              //       Export Page
              //     </Button>
              //     <Button
              //       disabled={
              //         !table.getIsSomeRowsSelected() &&
              //         !table.getIsAllRowsSelected()
              //       }
              //       onClick={() => {
              //         const data = table
              //           .getSelectedRowModel()
              //           .rows.map((value) => {
              //             const obj: any = {};

              //             header.forEach((e: any) => {
              //               obj[e.header] = value.getValue(e.accessor);
              //             });

              //             return obj;
              //           });

              //         exportCSVData(data);
              //       }}
              //       variant="contained"
              //     >
              //       Export Selected Record
              //     </Button>
              //   </Box>
              // )}
            />
          </Card>
          {/* {reportDetail == null ? (
            <></>
          ) : (
            <Grid container spacing={3}>
              <Card className="mt-4">
                <CardContent>
                  <h5 className="mb-3">Customer Report</h5>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      {reportDetail.map((e: any, i: any) => (
                        <Tab
                          label={e.FileName ?? "Report " + (i + 1)}
                          {...a11yProps(i)}
                        />
                      ))}
                    </Tabs>
                  </Box>
                  {reportDetail.map((e: any, i: any) => (
                    <CustomTabPanel value={value} index={i}>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {e.profile.map((e: any, i: any) => (
                          <FormControl
                            key={i}
                            sx={{ m: 1, width: "47%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor={e.Parameter}>
                              {e.Parameter}
                            </InputLabel>
                            <OutlinedInput
                              id={e.Parameter}
                              label={e.Parameter}
                              readOnly
                              value={e.ValueParameter}
                            />
                          </FormControl>
                        ))}
                      </Box>
                    </CustomTabPanel>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          )} */}
          <FileUploadModal
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
            onConfirm={() => {
              setOpenModal(false);
              getReport();
            }}
            isReport={true}
            triggerLoader={(loading: boolean) => {
              setIsLoading(loading);
            }}
            customerId={id}
          />
          <FullScreenLoader isLoading={isLoading} />
          <ErrorAlertModal
            isOpen={isError.active}
            onClose={() =>
              setError({
                error: "",
                active: false,
              })
            }
            title={"Error"}
            body={isError.error}
          ></ErrorAlertModal>
        </Container>
      </Box>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default HProfilePage;
